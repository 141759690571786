import DBC from './models/can/dbc';

const CorollaDBC = new DBC(`
CM_ "AUTOGENERATED FILE, DO NOT EDIT"


CM_ "Imported file _comma.dbc starts here"
BO_ 359 STEERING_IPAS_COMMA: 8 IPAS
 SG_ STATE : 7|4@0+ (1,0) [0|15] "" XXX
 SG_ ANGLE : 3|12@0- (1.5,0) [-510|510] "deg" XXX
 SG_ SET_ME_X10 : 23|8@0+ (1,0) [0|255] "" XXX
 SG_ SET_ME_X00 : 31|8@0+ (1,0) [0|255] "" XXX
 SG_ DIRECTION_CMD : 38|2@0+ (1,0) [0|3] "" XXX
 SG_ SET_ME_X40 : 47|8@0+ (1,0) [0|255] "" XXX
 SG_ SET_ME_X00 : 55|8@0+ (1,0) [0|255] "" XXX
 SG_ CHECKSUM : 63|8@0+ (1,0) [0|255] "" XXX

CM BO_ STEERING_IPAS_COMMA "Copy of msg 614 so we can do angle control while the Park Assist ECU is connected (Panda spoofs 614 with 359 on connector J70). Note that addresses 0x266 and 0x167 are checksum-invariant";

 BO_ 512 GAS_COMMAND: 6 EON
  SG_ GAS_COMMAND : 7|16@0+ (0.159375,-75.555) [0|1] "" INTERCEPTOR
  SG_ GAS_COMMAND2 : 23|16@0+ (0.159375,-151.111) [0|1] "" INTERCEPTOR
  SG_ ENABLE : 39|1@0+ (1,0) [0|1] "" INTERCEPTOR
  SG_ COUNTER_PEDAL : 35|4@0+ (1,0) [0|15] "" INTERCEPTOR
  SG_ CHECKSUM_PEDAL : 47|8@0+ (1,0) [0|255] "" INTERCEPTOR

 BO_ 513 GAS_SENSOR: 6 INTERCEPTOR
  SG_ INTERCEPTOR_GAS : 7|16@0+ (0.159375,-75.555) [0|1] "" EON
  SG_ INTERCEPTOR_GAS2 : 23|16@0+ (0.159375,-151.111) [0|1] "" EON
  SG_ STATE : 39|4@0+ (1,0) [0|15] "" EON
  SG_ COUNTER_PEDAL : 35|4@0+ (1,0) [0|15] "" EON
  SG_ CHECKSUM_PEDAL : 47|8@0+ (1,0) [0|255] "" EON

 VAL_ 513 STATE 5 "FAULT_TIMEOUT" 4 "FAULT_STARTUP" 3 "FAULT_SCE" 2 "FAULT_SEND" 1 "FAULT_BAD_CHECKSUM" 0 "NO_FAULT" ;


CM_ "Imported file _toyota_2017.dbc starts here"
VERSION ""


NS_ :
    NS_DESC_
    CM_
    BA_DEF_
    BA_
    VAL_
    CAT_DEF_
    CAT_
    FILTER
    BA_DEF_DEF_
    EV_DATA_
    ENVVAR_DATA_
    SGTYPE_
    SGTYPE_VAL_
    BA_DEF_SGTYPE_
    BA_SGTYPE_
    SIG_TYPE_REF_
    VAL_TABLE_
    SIG_GROUP_
    SIG_VALTYPE_
    SIGTYPE_VALTYPE_
    BO_TX_BU_
    BA_DEF_REL_
    BA_REL_
    BA_DEF_DEF_REL_
    BU_SG_REL_
    BU_EV_REL_
    BU_BO_REL_
    SG_MUL_VAL_

BS_:

BU_: XXX DSU HCU EPS IPAS CGW

BO_ 36 KINEMATICS: 8 XXX
 SG_ ACCEL_Y : 33|10@0+ (0.03589,-18.375) [0|65535] "m/s^2" XXX
 SG_ YAW_RATE : 1|10@0+ (0.244,-125) [0|65535] "deg/sec" XXX
 SG_ STEERING_TORQUE : 17|10@0+ (1,-512) [0|65535] "" XXX

BO_ 37 STEER_ANGLE_SENSOR: 8 XXX
 SG_ STEER_ANGLE : 3|12@0- (1.5,0) [-500|500] "deg" XXX
 SG_ STEER_FRACTION : 39|4@0- (0.1,0) [-0.7|0.7] "deg" XXX
 SG_ STEER_RATE : 35|12@0- (1,0) [-2000|2000] "deg/s" XXX

BO_ 166 BRAKE: 8 XXX
 SG_ BRAKE_AMOUNT : 7|8@0+ (1,0) [0|255] "" XXX
 SG_ BRAKE_PEDAL : 23|8@0+ (1,0) [0|255] "" XXX

BO_ 170 WHEEL_SPEEDS: 8 XXX
 SG_ WHEEL_SPEED_FR : 7|16@0+ (0.01,-67.67) [0|250] "kph" XXX
 SG_ WHEEL_SPEED_FL : 23|16@0+ (0.01,-67.67) [0|250] "kph" XXX
 SG_ WHEEL_SPEED_RR : 39|16@0+ (0.01,-67.67) [0|250] "kph" XXX
 SG_ WHEEL_SPEED_RL : 55|16@0+ (0.01,-67.67) [0|250] "kph" XXX

BO_ 180 SPEED: 8 XXX
 SG_ ENCODER : 39|8@0+ (1,0) [0|255] "" XXX
 SG_ SPEED : 47|16@0+ (0.01,0) [0|250] "kph" XXX
 SG_ CHECKSUM : 63|8@0+ (1,0) [0|255] "" XXX

BO_ 353 DSU_SPEED: 8 XXX
 SG_ FORWARD_SPEED : 15|16@0- (0.00390625,-30) [0|255] "kph" XXX

BO_ 466 PCM_CRUISE: 8 XXX
 SG_ GAS_RELEASED : 4|1@0+ (1,0) [0|1] "" XXX
 SG_ CRUISE_ACTIVE : 5|1@0+ (1,0) [0|1] "" XXX
 SG_ STANDSTILL_ON : 12|1@0+ (1,0) [0|1] "" XXX
 SG_ ACCEL_NET : 23|16@0- (0.001,0) [-20|20] "m/s2" XXX
 SG_ CRUISE_STATE : 55|4@0+ (1,0) [0|15] "" XXX
 SG_ CANCEL_REQ : 49|1@1+ (1,0) [0|1] "" XXX
 SG_ CHECKSUM : 63|8@0+ (1,0) [0|255] "" XXX

BO_ 467 PCM_CRUISE_2: 8 XXX
 SG_ MAIN_ON : 15|1@0+ (1,0) [0|1] "" XXX
 SG_ LOW_SPEED_LOCKOUT : 14|2@0+ (1,0) [0|3] "kph" XXX
 SG_ SET_SPEED : 23|8@0+ (1,0) [0|255] "kph" XXX
 SG_ CHECKSUM : 63|8@0+ (1,0) [0|255] "" XXX

BO_ 552 ACCELEROMETER: 8 XXX
 SG_ ACCEL_Z : 22|15@0- (1,0) [0|32767] "" XXX
 SG_ ACCEL_X : 6|15@0- (0.001,0) [-20|20] "m/s2" XXX

BO_ 560 BRAKE_MODULE2: 7 XXX
 SG_ BRAKE_PRESSED : 26|1@0+ (1,0) [0|1] "" XXX

BO_ 614 STEERING_IPAS: 8 IPAS
 SG_ STATE : 7|4@0+ (1,0) [0|15] "" XXX
 SG_ ANGLE : 3|12@0- (1.5,0) [-510|510] "deg" XXX
 SG_ SET_ME_X10 : 23|8@0+ (1,0) [0|255] "" XXX
 SG_ SET_ME_X00 : 31|8@0+ (1,0) [0|255] "" XXX
 SG_ DIRECTION_CMD : 38|2@0+ (1,0) [0|3] "" XXX
 SG_ SET_ME_X40 : 47|8@0+ (1,0) [0|255] "" XXX
 SG_ SET_ME_X00_1 : 55|8@0+ (1,0) [0|255] "" XXX
 SG_ CHECKSUM : 63|8@0+ (1,0) [0|255] "" XXX

BO_ 643 PRE_COLLISION: 7 DSU
 SG_ COUNTER : 7|8@0+ (1,0) [0|255] "" XXX
 SG_ SET_ME_X00 : 15|8@0+ (1,0) [0|255] "" XXX
 SG_ FORCE : 23|16@0- (2,0) [0|255] "N" XXX
 SG_ SET_ME_X002 : 33|8@0+ (1,0) [0|3] "" XXX
 SG_ BRAKE_STATUS : 39|3@0+ (1,0) [0|255] "" XXX
 SG_ STATE : 36|3@0+ (1,0) [0|255] "" XXX
 SG_ SET_ME_X003 : 40|1@0+ (1,0) [0|1] "" XXX
 SG_ PRECOLLISION_ACTIVE : 41|1@0+ (1,0) [0|255] "" XXX
 SG_ CHECKSUM : 55|8@0+ (1,0) [0|255] "" XXX

BO_ 740 STEERING_LKA: 5 XXX
 SG_ LKA_STATE : 31|8@0+ (1,0) [0|255] "" XXX
 SG_ STEER_REQUEST : 0|1@0+ (1,0) [0|1] "" XXX
 SG_ COUNTER : 6|6@0+ (1,0) [0|63] "" XXX
 SG_ SET_ME_1 : 7|1@0+ (1,0) [0|1] "" XXX
 SG_ STEER_TORQUE_CMD : 15|16@0- (1,0) [0|65535] "" XXX
 SG_ CHECKSUM : 39|8@0+ (1,0) [0|255] "" XXX

BO_ 742 LEAD_INFO: 8 DSU
 SG_ CHECKSUM : 63|8@0+ (1,0) [0|255] "" HCU
 SG_ LEAD_REL_SPEED : 23|12@0- (0.025,0) [-100|100] "m/s" HCU
 SG_ LEAD_LONG_DIST : 7|13@0+ (0.05,0) [0|300] "m" HCU

BO_ 835 ACC_CONTROL: 8 DSU
 SG_ ACCEL_CMD : 7|16@0- (0.001,0) [-20|20] "m/s2" HCU
 SG_ SET_ME_X01 : 23|2@0+ (1,0) [0|3] "" HCU
 SG_ DISTANCE : 20|1@0+ (1,0) [0|1] "" XXX
 SG_ MINI_CAR : 21|1@0+ (1,0) [0|1] "" XXX
 SG_ SET_ME_X3 : 19|4@0+ (1,0) [0|15] "" XXX
 SG_ SET_ME_1 : 30|1@0+ (1,0) [0|1] "" HCU
 SG_ RELEASE_STANDSTILL : 31|1@0+ (1,0) [0|1] "" HCU
 SG_ CANCEL_REQ : 24|1@0+ (1,0) [0|1] "" HCU
 SG_ CHECKSUM : 63|8@0+ (1,0) [0|255] "" XXX

BO_ 836 PRE_COLLISION_2: 8 DSU
 SG_ CHECKSUM : 63|8@0+ (1,0) [0|0] "" XXX

BO_ 869 DSU_CRUISE : 7 DSU
 SG_ RES_BTN : 3|1@0+ (1,0) [0|0] "" XXX
 SG_ SET_BTN : 2|1@0+ (1,0) [0|0] "" XXX
 SG_ CANCEL_BTN : 1|1@0+ (1,0) [0|0] "" XXX
 SG_ MAIN_ON : 0|1@0+ (1,0) [0|0] "" XXX
 SG_ SET_SPEED : 15|8@0+ (1,0) [0|0] "km/h" XXX
 SG_ CRUISE_REQUEST : 31|8@0+ (100,-12800) [0|0] "N" XXX
 SG_ LEAD_DISTANCE : 39|8@0+ (1,0) [0|0] "m" XXX

BO_ 921 PCM_CRUISE_SM: 8 XXX
 SG_ MAIN_ON : 4|1@0+ (1,0) [0|1] "" XXX
 SG_ CRUISE_CONTROL_STATE : 11|4@0+ (1,0) [0|15] "" XXX
 SG_ DISTANCE_LINES : 14|2@0+ (1,0) [0|3] "" XXX
 SG_ UI_SET_SPEED : 31|8@0+ (1,0) [0|255] "" XXX

BO_ 951 ESP_CONTROL: 8 ESP
 SG_ TC_DISABLED : 13|1@0+ (1,0) [0|1] "" XXX
 SG_ VSC_DISABLED : 12|2@0+ (1,0) [0|1] "" XXX
 SG_ BRAKE_LIGHTS_ACC : 18|1@0+ (1,0) [0|1] "" XXX

BO_ 1041 ACC_HUD: 8 DSU
 SG_ FCW : 4|1@0+ (1,0) [0|1] "" XXX
 SG_ SET_ME_X20 : 15|8@0+ (1,0) [0|1] "" XXX
 SG_ SET_ME_X10 : 39|8@0+ (1,0) [0|1] "" XXX
 SG_ SET_ME_X80 : 55|8@0+ (1,0) [0|1] "" XXX

BO_ 1042 LKAS_HUD: 8 XXX
 SG_ BARRIERS : 1|2@0+ (1,0) [0|3] "" XXX
 SG_ RIGHT_LINE : 3|2@0+ (1,0) [0|3] "" XXX
 SG_ LEFT_LINE : 5|2@0+ (1,0) [0|3] "" XXX
 SG_ SET_ME_X01 : 7|2@0+ (1,0) [0|3] "" XXX
 SG_ SET_ME_X01_2 : 11|2@0+ (1,0) [0|3] "" XXX
 SG_ LDA_ALERT : 9|2@0+ (1,0) [0|3] "" XXX
 SG_ TWO_BEEPS : 12|1@0+ (1,0) [0|1] "" XXX
 SG_ ADJUSTING_CAMERA : 13|1@0+ (1,0) [0|1] "" XXX
 SG_ LDA_MALFUNCTION : 15|1@0+ (1,0) [0|1] "" XXX
 SG_ REPEATED_BEEPS : 32|1@0+ (1,0) [0|1] "" XXX
 SG_ SET_ME_X0C : 23|8@0+ (1,0) [0|1] "" XXX
 SG_ SET_ME_X2C : 47|8@0+ (1,0) [0|1] "" XXX
 SG_ SET_ME_X38 : 55|8@0+ (1,0) [0|1] "" XXX
 SG_ SET_ME_X02 : 63|8@0+ (1,0) [0|1] "" XXX

BO_ 1043 TIME : 8 CGW
 SG_ YEAR : 7|8@0+ (1,0) [0|0] "year" XXX
 SG_ MONTH : 15|8@0+ (1,0) [0|0] "month" XXX
 SG_ DAY : 23|8@0+ (1,0) [0|0] "day" XXX
 SG_ HOUR : 31|8@0+ (1,0) [0|0] "hour" XXX
 SG_ MINUTE : 39|8@0+ (1,0) [0|0] "minute" XXX
 SG_ GMT_DIFF : 55|1@0+ (1,0) [0|0] "" XXX
 SG_ GMTDIFF_HOURS : 54|4@0+ (1,0) [0|0] "hours" XXX
 SG_ GMTDIFF_MINUTES : 50|6@0+ (1,0) [0|0] "minutes" XXX
 SG_ SUMMER : 60|1@0+ (1,0) [0|0] "" XXX

BO_ 1408 VIN_PART_1: 8 CGW
 SG_ VIN_1 : 7|8@0+ (1,0) [0|0] "" XXX
 SG_ VIN_2 : 15|8@0+ (1,0) [0|0] "" XXX
 SG_ VIN_3 : 23|8@0+ (1,0) [0|0] "" XXX
 SG_ VIN_4 : 31|8@0+ (1,0) [0|0] "" XXX
 SG_ VIN_5 : 39|8@0+ (1,0) [0|0] "" XXX
 SG_ VIN_6 : 47|8@0+ (1,0) [0|0] "" XXX
 SG_ VIN_7 : 55|8@0+ (1,0) [0|0] "" XXX
 SG_ VIN_8 : 63|8@0+ (1,0) [0|0] "" XXX

BO_ 1409 VIN_PART_2: 8 CGW
 SG_ VIN_9 : 7|8@0+ (1,0) [0|0] "" XXX
 SG_ VIN_10 : 15|8@0+ (1,0) [0|0] "" XXX
 SG_ VIN_11 : 23|8@0+ (1,0) [0|0] "" XXX
 SG_ VIN_12 : 31|8@0+ (1,0) [0|0] "" XXX
 SG_ VIN_13 : 39|8@0+ (1,0) [0|0] "" XXX
 SG_ VIN_14 : 47|8@0+ (1,0) [0|0] "" XXX
 SG_ VIN_15 : 55|8@0+ (1,0) [0|0] "" XXX
 SG_ VIN_16 : 63|8@0+ (1,0) [0|0] "" XXX

BO_ 1410 VIN_PART_3: 8 CGW
 SG_ VIN_17 : 7|8@0+ (1,0) [0|0] "" XXX

BO_ 1553 UI_SETTING: 8 XXX
 SG_ UNITS : 26|2@0+ (1,0) [0|3] "" XXX

BO_ 1556 STEERING_LEVERS: 8 XXX
 SG_ TURN_SIGNALS : 29|2@0+ (1,0) [0|3] "" XXX

BO_ 1568 SEATS_DOORS: 8 XXX
 SG_ SEATBELT_DRIVER_UNLATCHED : 62|1@0+ (1,0) [0|1] "" XXX
 SG_ DOOR_OPEN_FL : 45|1@0+ (1,0) [0|1] "" XXX
 SG_ DOOR_OPEN_RL : 42|1@0+ (1,0) [0|1] "" XXX
 SG_ DOOR_OPEN_RR : 43|1@0+ (1,0) [0|1] "" XXX
 SG_ DOOR_OPEN_FR : 44|1@0+ (1,0) [0|1] "" XXX

BO_ 1570 LIGHT_STALK: 8 SCM
 SG_ AUTO_HIGH_BEAM : 37|1@0+ (1,0) [0|1] "" XXX

BO_ 1161 RSA1: 8 FCM
 SG_ TSGN1 : 7|8@0+ (1,0) [0|0] "" XXX
 SG_ TSGNGRY1 : 12|3@0+ (1,0) [0|0] "" XXX
 SG_ TSGNHLT1 : 9|2@0+ (1,0) [0|0] "" XXX
 SG_ SPDVAL1 : 23|8@0+ (1,0) [0|0] "kph" XXX
 SG_ SPLSGN1 : 31|4@0+ (1,0) [0|0] "" XXX
 SG_ SPLSGN2 : 27|4@0+ (1,0) [0|0] "" XXX
 SG_ TSGN2 : 39|8@0+ (1,0) [0|0] "" XXX
 SG_ TSGNGRY2 : 44|3@0+ (1,0) [0|0] "" XXX
 SG_ TSGNHLT2 : 41|2@0+ (1,0) [0|0] "" XXX
 SG_ SPDVAL2 : 55|8@0+ (1,0) [0|0] "" XXX
 SG_ BZRRQ_P : 63|2@0+ (1,0) [0|0] "" XXX
 SG_ BZRRQ_A : 61|2@0+ (1,0) [0|0] "" XXX
 SG_ SYNCID1 : 59|4@0+ (1,0) [0|0] "" XXX

BO_ 1162 RSA2: 8 FCM
 SG_ TSGN3 : 7|8@0+ (1,0) [0|0] "" XXX
 SG_ TSGNGRY3 : 12|3@0+ (1,0) [0|0] "" XXX
 SG_ TSGNHLT3 : 9|2@0+ (1,0) [0|0] "" XXX
 SG_ SPLSGN3 : 31|4@0+ (1,0) [0|0] "" XXX
 SG_ SPLSGN4 : 27|4@0+ (1,0) [0|0] "" XXX
 SG_ TSGN4 : 39|8@0+ (1,0) [0|0] "" XXX
 SG_ TSGNGRY4 : 44|3@0+ (1,0) [0|0] "" XXX
 SG_ TSGNHLT4 : 41|2@0+ (1,0) [0|0] "" XXX
 SG_ DPSGNREQ : 54|1@0+ (1,0) [0|0] "" XXX
 SG_ SGNNUMP : 53|3@0+ (1,0) [0|0] "" XXX
 SG_ SGNNUMA : 50|3@0+ (1,0) [0|0] "" XXX
 SG_ SPDUNT : 63|2@0+ (1,0) [0|0] "" XXX
 SG_ TSRWMSG : 61|2@0+ (1,0) [0|0] "" XXX
 SG_ SYNCID2 : 59|4@0+ (1,0) [0|0] "" XXX

BO_ 1163 RSA3: 8 FCM
 SG_ TSREQPD : 7|1@0+ (1,0) [0|0] "" XXX
 SG_ TSRMSW : 6|1@0+ (1,0) [0|0] "" XXX
 SG_ OTSGNNTM : 5|2@0+ (1,0) [0|0] "" XXX
 SG_ NTLVLSPD : 3|2@0+ (1,0) [0|0] "" XXX
 SG_ OVSPNTM : 1|2@0+ (1,0) [0|0] "" XXX
 SG_ OVSPVALL : 11|4@0+ (1,-5) [0|0] "" XXX
 SG_ OVSPVALM : 19|4@0+ (1,-5) [0|0] "" XXX
 SG_ OVSPVALH : 27|4@0+ (1,-5) [0|0] "" XXX
 SG_ TSRSPU : 33|2@0+ (1,0) [0|0] "" XXX

CM_ SG_ 36 ACCEL_Y "unit is tbd";
CM_ SG_ 36 YAW_RATE "verify";
CM_ SG_ 36 STEERING_TORQUE "does not seem the steer torque, tbd";
CM_ SG_ 37 STEER_FRACTION "1/15th of the signal STEER_ANGLE, which is 1.5 deg; note that 0x8 is never set";
CM_ SG_ 37 STEER_RATE "factor is tbd";
CM_ SG_ 466 ACCEL_NET "net acceleration produced by the system, given ACCEL_CMD, road grade and other factors";
CM_ SG_ 467 SET_SPEED "43 kph are shown as 28mph, so conversion isnt perfect";
CM_ SG_ 467 LOW_SPEED_LOCKOUT "in low speed lockout, system would always disengage below 28mph";
CM_ SG_ 560 BRAKE_PRESSED "another brake pressed?";
CM_ SG_ 608 STEER_TORQUE_DRIVER "driver torque";
CM_ SG_ 608 STEER_OVERRIDE "set when driver torque exceeds a certain value";
CM_ SG_ 614 ANGLE "set to measured angle when ipas control isn't active";
CM_ SG_ 643 COUNTER "only used on cars that use this msg for cruise control";
CM_ SG_ 643 BRAKE_STATUS "only used on cars that use this msg for cruise control";
CM_ SG_ 643 PRECOLLISION_ACTIVE "set 0.5s before any braking";
CM_ SG_ 921 UI_SET_SPEED "set speed shown in UI with user set unit";
CM_ SG_ 951 BRAKE_LIGHTS_ACC "brake lights when ACC commands decel";
CM_ SG_ 1042 SET_ME_1 "unclear what this is, but it's always 1 in drive traces";
CM_ SG_ 1042 REPEATED_BEEPS "recommended for fcw and other important alerts";
CM_ SG_ 1161 SPDVAL1 "Numbers 0-199 is displayed, 200-254 displays circle without number and 255 is for no limit.";
CM_ SG_ 1161 SYNCID1 "counter from 1 to f at 1 Hz";
CM_ SG_ 1161 SPDVAL2 "conditional speed value 70"
CM_ SG_ 1162 SGNNUMP "1 if SPDVAL1 is set, otherwise 0";
CM_ SG_ 1162 SYNCID2 "counter from 1 to f at 1 Hz";
CM_ SG_ 1163 TSREQPD "always 1";
CM_ SG_ 1163 TSRMSW "always 1";
CM_ SG_ 1163 OTSGNNTM "always 3";
CM_ SG_ 1163 NTLVLSPD "always 3";
CM_ SG_ 1163 OVSPNTM "always 3";
CM_ SG_ 1163 OVSPVALL "-5 at start then 2 after 2 seconds";
CM_ SG_ 1163 OVSPVALM "-5 at start then 5 after 2 seconds";
CM_ SG_ 1163 OVSPVALH "-5 at start then 10 after 2 seconds";
CM_ SG_ 1163 TSRSPU "always 1";

VAL_ 466 CRUISE_STATE 8 "active" 7 "standstill" 1 "off";
VAL_ 467 LOW_SPEED_LOCKOUT 2 "low speed locked" 1 "ok";
VAL_ 614 STATE 3 "enabled" 1 "disabled";
VAL_ 614 DIRECTION_CMD 3 "right" 2 "center" 1 "left";
VAL_ 643 STATE 0 "normal" 1 "adaptive_cruise_control" 3 "emergency_braking";
VAL_ 921 CRUISE_CONTROL_STATE 2 "disabled" 11 "hold" 10 "hold_waiting_user_cmd" 6 "enabled" 5 "faulted";
VAL_ 1042 LDA_ALERT 3 "hold with continuous beep" 2 "LDA unavailable" 1 "hold" 0 "none";
VAL_ 1042 BARRIERS 3 "both" 2 "right" 1 "left" 0 "none";
VAL_ 1042 RIGHT_LINE 3 "orange" 2 "faded" 1 "solid" 0 "none";
VAL_ 1042 LEFT_LINE 3 "orange" 2 "faded" 1 "solid" 0 "none";
VAL_ 1553 UNITS 1 "km" 2 "miles";
VAL_ 1556 TURN_SIGNALS 3 "none" 2 "right" 1 "left";
VAL_ 1161 TSGN1 1 "speed sign" 0 "none";
VAL_ 1161 TSGN2 1 "speed sign" 0 "none";
VAL_ 1161 SPLSGN2 15 "conditional blank" 4 "wet road" 5 "rain" 0 "none";
VAL_ 1162 TSGN3 0 "none" 1 "speed sign" 2 "0 unlimited" 7 "unlimited" 16 "highway" 17 "no highway" 18 "motorway" 19 "no motorway" 20 "in city" 21 "outside city" 22 "pedestrian area" 23 "no pedestrian area" 65 "no overtaking left" 66 "no overtaking right" 67 "overtaking allowed again" 129 "no entry";
VAL_ 1162 SPLSGN3 15 "conditional blank" 4 "wet road" 5 "rain" 0 "none";


CM_ "CHFFR_METRIC 37 STEER_ANGLE STEER_ANGLE 0.36 180";

CM_ "toyota_nodsu_pt.dbc starts here"



BO_ 401 STEERING_LTA: 8 XXX
 SG_ COUNTER : 7|8@0+ (1,0) [0|255] "" XXX
 SG_ CHECKSUM : 63|8@0+ (1,0) [0|255] "" XXX
 SG_ SETME_X3 : 29|2@0+ (1,0) [0|3] "" XXX
 SG_ PERCENTAGE : 39|8@0+ (1,0) [0|255] "" XXX
 SG_ SETME_X64 : 47|8@0+ (1,0) [0|255] "" XXX
 SG_ ANGLE : 55|8@0- (0.5,0) [0|255] "" XXX
 SG_ STEER_ANGLE_CMD : 15|16@0- (0.0573,0) [-540|540] "" XXX
 SG_ STEER_REQUEST : 25|1@0+ (1,0) [0|1] "" XXX
 SG_ BIT : 30|1@0+ (1,0) [0|1] "" XXX

BO_ 550 BRAKE_MODULE: 8 XXX
 SG_ BRAKE_PRESSURE : 0|9@0+ (1,0) [0|511] "" XXX
 SG_ BRAKE_POSITION : 16|9@0+ (1,0) [0|511] "" XXX
 SG_ BRAKE_PRESSED : 37|1@0+ (1,0) [0|1] "" XXX

BO_ 705 GAS_PEDAL: 8 XXX
 SG_ GAS_RELEASED : 3|1@0+ (1,0) [0|1] "" XXX
 SG_ GAS_PEDAL : 55|8@0+ (0.005,0) [0|1] "" XXX

BO_ 608 STEER_TORQUE_SENSOR: 8 XXX
 SG_ STEER_TORQUE_EPS : 47|16@0- (0.73,0) [-20000|20000] "" XXX
 SG_ STEER_TORQUE_DRIVER : 15|16@0- (1,0) [-32768|32767] "" XXX
 SG_ STEER_OVERRIDE : 0|1@0+ (1,0) [0|1] "" XXX
 SG_ CHECKSUM : 63|8@0+ (1,0) [0|255] "" XXX
 SG_ STEER_ANGLE : 31|16@0- (0.0573,0) [-500|500] "" XXX

BO_ 610 EPS_STATUS: 8 EPS
 SG_ IPAS_STATE : 3|4@0+ (1,0) [0|15] "" XXX
 SG_ LKA_STATE : 31|7@0+ (1,0) [0|127] "" XXX
 SG_ TYPE : 24|1@0+ (1,0) [0|1] "" XXX
 SG_ CHECKSUM : 63|8@0+ (1,0) [0|255] "" XXX

BO_ 956 GEAR_PACKET: 8 XXX
 SG_ SPORT_ON : 2|1@0+ (1,0) [0|1] "" XXX
 SG_ GEAR : 13|6@0+ (1,0) [0|63] "" XXX
 SG_ ECON_ON : 40|1@0+ (1,0) [0|1] "" XXX

CM_ SG_ 548 BRAKE_PRESSURE "seems prop to pedal force";
CM_ SG_ 548 BRAKE_POSITION "seems proportional to pedal displacement, unclear the max value of 0x1c8";
CM_ SG_ 610 TYPE "seems 1 on Corolla, 0 on all others";
VAL_ 610 IPAS_STATE 5 "override" 3 "enabled" 1 "disabled";
VAL_ 610 LKA_STATE 25 "temporary_fault" 9 "temporary_fault2" 5 "active" 1 "standby";
VAL_ 956 GEAR 0 "D" 1 "S" 8 "N" 16 "R" 32 "P";
VAL_ 956 SPORT_ON 0 "off" 1 "on";
VAL_ 956 ECON_ON 0 "off" 1 "on";
`);

export default CorollaDBC;
